import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { ClinicMedical } from '@styled-icons/fa-solid/ClinicMedical'
import { Clipboard } from '@styled-icons/fa-regular/Clipboard'
import { Pencil } from '@styled-icons/boxicons-solid/Pencil'
import { ThreeBars } from '@styled-icons/octicons'
import { Search } from '@styled-icons/evaicons-solid/Search'
import { DotsThreeHorizontal } from '@styled-icons/entypo/DotsThreeHorizontal'
import { LabelImportant } from '@styled-icons/material/LabelImportant'
import { VideoCamera } from '@styled-icons/zondicons/VideoCamera'
import { Warning } from '@styled-icons/material/Warning'
import { Notifications } from '@styled-icons/material/Notifications'

import ConsultSearch from 'components/ConsultSearch/ConsultSearch'
import ConsultsTable from 'components/ConsultTableWithFiltersComponent/ConsultsTable'
import { ConsultFilterTab } from 'components/ConsultTableWithFiltersComponent/ConsultFilterTab'
import Divider from 'components/Divider'
import useExperiment from 'hooks/useExperiment'
import { AmplitudeFeatureFlagKeys } from 'services/amplitude'
import {
  retrieveConsultCounts,
  retrieveConsults,
  retrieveUnassignedConsults,
  searchConsults,
} from 'services/consultsApi'
import { SubtitleStyles } from 'styles/fonts'
import {
  ConsultType,
  ConsultFilterTabType,
  ConsultScopesEnum,
  EscalationsScope,
  PriorityLevelEnum,
} from 'types/consultType'
import { StaffAuthorizations } from 'types/staffType'
import { SortOrderDirection } from 'types/genericTypes'
import { useUserContext } from 'userContext'

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
`

const FilterContainer = styled.div`
  border-right: solid 1px ${props => props.theme.colors.lightGrey2};
  height: calc(100vh - 85px);
  width: 20%;
`

const TableContainer = styled.div`
  background: ${props => props.theme.colors.lightGrey1};
  height: calc(100vh - 95px);
  overflow: scroll;
  padding: 0 20px;
  width: 80%;
`

const LinkContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => (props.isActive ? props.theme.colors.primaryGreen : props.theme.colors.black)};
  text-decoration: none;
  // Needs to be rgba to use opacity, might be able to clean this up in the future.
  background: ${props => (props.isActive ? 'rgba(20, 131, 102, 0.1)' : '')};
  padding: 18px 20px;
`

const StyledLink = styled.a`
  text-decoration: none;
`

const StyledSpan = styled.span`
  padding-left: 10px;
`

const Subtitle = styled.div`
  ${SubtitleStyles};
  color: ${props => props.theme.colors.midGrey1};
  padding-left: 14px;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  height: 58px;
  width: 60%;
`

const ConsultsTableWithFilters = () => {
  const pageSize = 50
  const refreshRate = 60000
  const SearchStatus = 'search'
  const router = useRouter()
  const [consults, setConsults] = useState<Array<ConsultType>>([])
  const [consultTotalCount, setConsultTotalCount] = useState(0)
  const [consultCounts, setConsultCounts] = useState<Record<string, number>>()
  const [isLoading, setIsLoading] = useState(false)
  const user = useUserContext()
  const canViewEscalations = user.user?.authorizations?.includes(
    StaffAuthorizations.ViewEscalations,
  )

  const amplitudeContext = useExperiment()

  const canViewSyncConsults = !!user.user?.healthieId
  const careCallActionsQueueEnabled =
    canViewSyncConsults &&
    amplitudeContext.isVariantActive(AmplitudeFeatureFlagKeys.CareCallsActionQueue, 'on')

  const handleGetConsults = async ({
    page = 0,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => {
    setIsLoading(true)
    try {
      const response = await retrieveConsults({
        page,
        pageSize,
        sortBy,
        sortDirection,
        ...router.query,
      })
      setConsults(response.consults)
      setConsultTotalCount(response.totalCount)
      setIsLoading(false)
    } catch (_e) {
      // do nothing
    }
  }

  const handleGetEscalationConsults = async ({
    page = 0,
    sortDirection,
    sortBy,
  }: {
    page?: number
    sortDirection: SortOrderDirection
    sortBy: string
  }) => {
    setIsLoading(true)
    const response = await retrieveUnassignedConsults({
      page,
      pageSize,
      sortBy,
      sortDirection,
      minPriority: PriorityLevelEnum.Urgent,
    })
    setConsults(response.consults)
    setConsultTotalCount(response.totalCount)
    setIsLoading(false)
  }

  const handleGetConsultCounts = async () => {
    try {
      const counts = await retrieveConsultCounts()
      setConsultCounts(counts)
    } catch (_e) {
      // do nothing
    }
  }

  const handleSearchConsults = async ({
    customerName,
    consultId,
  }: {
    customerName?: string
    consultId?: string
  }) => {
    setIsLoading(true)
    try {
      const response = await searchConsults({ customerName, consultId })
      setConsults(response.consults)
      setConsultTotalCount(response.consults.length)
    } catch {
      // no results found
      // let it fail silently and show table default null text
      setConsults([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const refreshCounts = async () => {
      if (document.hidden) {
        return
      }

      await handleGetConsultCounts()
    }

    const refreshInterval = window.setInterval(refreshCounts, refreshRate)
    window.addEventListener('visibilitychange', refreshCounts)
    return () => {
      window.removeEventListener('visibilitychange', refreshCounts)
      window.clearInterval(refreshInterval)
    }
  }, [])

  useEffect(() => {
    handleGetConsultCounts()
    if (router.query?.scope === SearchStatus) {
      setConsults([])
      setConsultTotalCount(0)
    } else if (router.query?.scope === EscalationsScope) {
      handleGetEscalationConsults({ sortBy: 'createdAt', sortDirection: SortOrderDirection.ASC })
    } else if (router.query?.scope === ConsultScopesEnum.Synchronous) {
      handleGetConsults({ sortBy: 'scheduledAt', sortDirection: SortOrderDirection.ASC })
    } else {
      handleGetConsults({ sortBy: 'createdAt', sortDirection: SortOrderDirection.ASC })
    }
  }, [router.query])

  const generateTableTitle = () => {
    switch (router.query?.scope) {
      case ConsultScopesEnum.Urgent:
        return `${ConsultFilterTabType.Urgent} Consultations`
      case ConsultScopesEnum.Prioritized:
        return `${ConsultFilterTabType.Important} Consultations`
      case ConsultScopesEnum.NotStarted:
        return `${ConsultFilterTabType.NotStarted} Consultations`
      case ConsultScopesEnum.Synchronous:
        return ConsultFilterTabType.Live
      case ConsultScopesEnum.SynchronousNeedsAttention:
        return 'Care Calls Requiring Action'
      case ConsultScopesEnum.Started:
        return `${ConsultFilterTabType.InProgress} Consultations`
      case ConsultScopesEnum.Deferred:
        return `${ConsultFilterTabType.Deferred} Consultations`
      case ConsultScopesEnum.Completed:
        return `${ConsultFilterTabType.Completed} Consultations`
      case EscalationsScope:
        return ConsultFilterTabType.PatientEscalations
      case SearchStatus:
        return 'Search Consultations'
      case ConsultScopesEnum.Open:
      default:
        return `${ConsultFilterTabType.AllConsultations} Consultations`
    }
  }

  const handleFilterChange = async ({ scope }: { scope?: ConsultScopesEnum | string }) => {
    await router.push({ pathname: '/', query: { scope } })
  }

  const styledLinks = [
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Open }),
      isActive: router.query.scope == ConsultScopesEnum.Open || router.query.scope == null,
      icon: <ThreeBars size={18} />,
      title: ConsultFilterTabType.AllConsultations,
      count: consultCounts ? consultCounts.open : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Urgent }),
      isActive: router.query.scope == ConsultScopesEnum.Urgent,
      icon: <Warning size={18} />,
      title: ConsultFilterTabType.Urgent,
      count: consultCounts ? consultCounts.urgent : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Prioritized }),
      isActive: router.query.scope == ConsultScopesEnum.Prioritized,
      icon: <LabelImportant size={18} />,
      title: ConsultFilterTabType.Important,
      count: consultCounts ? consultCounts.prioritized : 0,
    },
    ...(canViewSyncConsults
      ? [
          {
            onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Synchronous }),
            isActive: router.query.scope == ConsultScopesEnum.Synchronous,
            icon: <VideoCamera size={18} />,
            title: ConsultFilterTabType.Live,
            count: consultCounts ? consultCounts.synchronous : 0,
            alert: (consultCounts?.imminent || 0) > 0,
          },
        ]
      : []),
    ...(careCallActionsQueueEnabled
      ? [
          {
            onClick: () =>
              handleFilterChange({ scope: ConsultScopesEnum.SynchronousNeedsAttention }),
            isActive: router.query.scope == ConsultScopesEnum.SynchronousNeedsAttention,
            icon: <Notifications size={18} />,
            title: 'Scheduled - Needs Attention',
            alert: (consultCounts?.synchronousNeedsAttention || 0) > 0,
            count: consultCounts ? consultCounts.synchronousNeedsAttention : 0,
          },
        ]
      : []),
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.NotStarted }),
      isActive: router.query.scope == ConsultScopesEnum.NotStarted,
      icon: <Clipboard size={18} />,
      title: ConsultFilterTabType.NotStarted,
      count: consultCounts ? consultCounts.new + consultCounts.assigned : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Started }),
      isActive: router.query.scope == ConsultScopesEnum.Started,
      icon: <Pencil size={18} />,
      title: ConsultFilterTabType.InProgress,
      count: consultCounts ? consultCounts.started : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Deferred }),
      isActive: router.query.scope == ConsultScopesEnum.Deferred,
      icon: <DotsThreeHorizontal size={18} />,
      title: ConsultFilterTabType.Deferred,
      count: consultCounts ? consultCounts.deferred : 0,
    },
    {
      onClick: () => handleFilterChange({ scope: ConsultScopesEnum.Completed }),
      isActive: router.query.scope == ConsultScopesEnum.Completed,
      icon: <CheckCircle size={18} />,
      title: ConsultFilterTabType.Completed,
      count: undefined,
    },
  ]

  if (canViewEscalations) {
    styledLinks.unshift({
      onClick: () => handleFilterChange({ scope: EscalationsScope }),
      isActive: router.query.scope == EscalationsScope,
      icon: <ClinicMedical size={18} />,
      title: ConsultFilterTabType.PatientEscalations,
      count: consultCounts ? consultCounts.urgentUnassigned : 0,
    })
  }

  const tableComponent = () => {
    if (router.query.scope == EscalationsScope && !canViewEscalations) {
      return null
    }
    if (!consults) {
      return null
    }

    return (
      <ConsultsTable
        page={0}
        pageSize={pageSize}
        getConsults={
          router.query.scope == EscalationsScope ? handleGetEscalationConsults : handleGetConsults
        }
        totalCount={consultTotalCount}
        data={consults}
        isLoading={isLoading}
        scope={router.query.scope as string}
        consultRefreshRate={refreshRate}
      />
    )
  }

  return (
    <StyledContainer>
      <FilterContainer>
        <StyledLink onClick={() => handleFilterChange({ scope: SearchStatus })}>
          <LinkContainer>
            <Search size={18} />
            <StyledSpan>Search</StyledSpan>
          </LinkContainer>
        </StyledLink>
        <Divider margin={0} />
        <List>
          {styledLinks.map(link => {
            return <ConsultFilterTab key={link.title} {...link} />
          })}
        </List>
      </FilterContainer>
      <TableContainer>
        <TitleBox>
          <Typography variant="h5">{generateTableTitle()}</Typography>{' '}
          <Subtitle>{consultTotalCount} consultations</Subtitle>
        </TitleBox>
        {router.query.scope === SearchStatus && (
          <ConsultSearch searchConsults={handleSearchConsults} />
        )}
        {tableComponent()}
      </TableContainer>
    </StyledContainer>
  )
}

export default ConsultsTableWithFilters
