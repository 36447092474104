import React from 'react'
import Alignment from '../Alignment'
import Button from '../Button'
import FieldError from '../FieldError'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { StyledSearchInput } from './ConsultSearch'

type SearchForm = {
  consultId: string
}

interface ConsultSearchById {
  searchConsults: ({ consultId }: { consultId: string }) => void
}

export const StyledSpan = styled.span`
  min-width: 120px;
`

const ConsultSearchById = ({ searchConsults }: ConsultSearchById) => {
  const { register, handleSubmit, formState } = useForm<SearchForm>({
    mode: 'onChange',
  })

  const onSubmitId = ({ consultId }: SearchForm) => {
    searchConsults({ consultId })
  }

  return (
    <form key="searchById" onSubmit={handleSubmit(onSubmitId)}>
      <StyledSpan>Consultation ID:</StyledSpan>
      <StyledSearchInput
        type="text"
        align="left"
        register={register('consultId', { required: true })}
      />
      <Alignment alignItems="baseline">
        <Button backgroundColor="darkGreen" whiteText type="submit" disabled={!formState.isValid}>
          Search
        </Button>
        <FieldError visible={!!formState.errors.consultId?.message}>
          {formState.errors.consultId?.message}
        </FieldError>
      </Alignment>
    </form>
  )
}

export default ConsultSearchById
