// useExperiment.ts
import { useContext } from 'react'

import {
  AmplitudeExperimentContext,
  AmplitudeExperimentContextValue,
} from 'components/AmplitudeExperiments/AmplitudeExperimentContext'

const useExperiment = (): AmplitudeExperimentContextValue => {
  const context = useContext(AmplitudeExperimentContext)

  if (context === undefined) {
    throw new Error('useExperiment must be used within a AmplitudeExperimentProvider')
  }

  return context!
}

export default useExperiment
