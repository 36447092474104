import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { withAuthSync } from '../utils/auth'
import ConsultsTableWithFilters from '../components/ConsultTableWithFiltersComponent/ConsultsTableWithFilters'

const PageContainer = styled.div`
  display: flex;
`

const Home = () => {
  return (
    <Layout titleText="Consult Dashboard" hasPadding={false}>
      <PageContainer>
        <ConsultsTableWithFilters />
      </PageContainer>
    </Layout>
  )
}

export default withAuthSync(Home)
