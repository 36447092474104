import React from 'react'
import Input from '../Input'
import { useForm } from 'react-hook-form'
import Alignment from '../Alignment'
import styled from 'styled-components'
import Divider from '../Divider'
import Button from '../Button'
import FieldError from '../FieldError'
import ConsultSearchById from './ConsultSearchById'

type SearchForm = {
  consultId?: string
  customerName: string
}

interface ConsultSearchProps {
  searchConsults: (key: { [key: string]: string }) => void
}

const Container = styled.div`
  padding-bottom: 20px;
`

export const StyledSearchInput = styled(Input)`
  border: 1px solid ${props => props.theme.colors.lightGrey2};
  border-radius: 50px;
  background: ${props => props.theme.colors.white};
  line-height: 25px;
  padding: 10px;
  margin: 20px 0;
  width: 50%;
`

export const StyledSpan = styled.span`
  min-width: 120px;
`

const ConsultSearch = ({ searchConsults }: ConsultSearchProps) => {
  const { register, handleSubmit, formState } = useForm<SearchForm>({
    mode: 'onChange',
  })

  const onSubmit = ({ customerName }: SearchForm) => {
    searchConsults({ customerName })
  }

  return (
    <Container>
      <ConsultSearchById searchConsults={searchConsults} />
      <Divider margin={32} />
      <form key="search" onSubmit={handleSubmit(onSubmit)}>
        <StyledSpan>Patient Name:</StyledSpan>
        <StyledSearchInput
          type="text"
          align="left"
          register={register('customerName', { required: true, minLength: 2 })}
        />
        <Alignment alignItems="baseline">
          <Button backgroundColor="darkGreen" whiteText type="submit" disabled={!formState.isValid}>
            Search
          </Button>
          <FieldError visible={!!formState.errors.customerName?.message}>
            {formState.errors.customerName?.message}
          </FieldError>
        </Alignment>
      </form>
    </Container>
  )
}

export default ConsultSearch
